html {
  font-size: 62.5%;
}

body {
  margin: 0;
  overflow-x: hidden; 
  font-family: 'Rubik', sans-serif;
  background-color: $super-light-gray;
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-device-pixel-ratio: 1.5){

  html,
  body{
    width:100%;
    overflow-x:hidden;
  }

}

.App {
  display: grid;
  grid-template-columns: 12.5vw 75vw 12.5vw;

  & > *:not(.Navbar) {
    grid-column: 2;
    justify-self: center;
    padding: 20px 5px;
    margin-bottom: 30px;
    margin-top: 100px;
    font-size: 2rem;
    display: grid;
    justify-content: center;
    background-color: white;
    width: 100%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (max-width: 1000px) {
  .App {
    display: grid;
    grid-template-columns: 2.5vw 95vw 2.5vw;

    .go-back {
      left: 2.7vw;
    }
  }
}

h1 {
  font-size: 4rem;
  text-align: center;
}

a,
.clickable {
  text-decoration: none;
  cursor: pointer;
  color: $blue;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $light-blue;
  }
}

ul {
  list-style: none;
  display: grid;
  row-gap: 20px;
}

.red-text {
  color: rgb(175, 24, 24);
}

.fa-trash, .red-text-clickable {
  color: rgb(175, 24, 24);
  cursor: pointer;

  &:hover {
    color: rgb(131, 21, 21);
  }
}

.green-text {
  color: green;
}

.pink-text {
  color: $pink;
}

.go-back {
  font-size: 4rem;
  position: fixed;
  top: 75px;
  left: 10px;
}