.NewListing {
  font-size: 2rem;
  justify-items: center;
  display: grid;

  .header-label {
    font-size: 3rem;
    margin-bottom: 15px;
  }

  select {
    margin-bottom: 30px;
    width: 240px;
  }

  .ListingForm {
    width: 75%;

    button {
      width: 240px;
      justify-self: center;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .NewListing {
    .ListingForm {
      width: 100%;
    }
  }
}