.JobApplication {
  justify-items: center;

  p {
    width: 80%;
  }

  .job-application-form {
    width: 75%;

    button {
      width: fit-content;
    }
  }
}