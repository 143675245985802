form {
  display: grid;
  align-items: center;
  row-gap: 2rem;
  width: 80%;
  margin-bottom: 15px;
}

button {
  cursor: pointer;
  font-size: inherit;
  padding: 10px;
  color: white;
  outline: none;
  background-color: $blue;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: $light-blue;
  }

  &:disabled {
    background-color: $light-blue;
    color: black;
    cursor: not-allowed;
  }
}

input {
  font-family: inherit;
  font-size: inherit;
  padding: 15px 5px;
  border: 0;
  border-bottom: 1px solid $pink;
  transition: all 0.3s ease-in-out;

  &:disabled {
    color: $dark-gray;
  }

  &:focus {
    outline: none;
    border-radius: 2px;
    border-bottom: 1px solid $light-pink;
    -webkit-box-shadow: 0px 0px 10px 0px #000000; 
    box-shadow: 0px 0px 10px 0px #000000;
  }

  &[type='radio'],
  &[type='checkbox'] {
    &:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  &[type='number'],
  &[type='date'] {
    width: fit-content;
  }
}

select {
  font-family: inherit;
  font-size: inherit;
  height: 2em;
  background-color: inherit;
  border: 1px solid $pink;
  transition: all 0.3s ease-in-out;

  &:focus {
    outline: none;
    border: 1px solid $light-pink;
  }
}