.ForgotPassword {

  p {
    text-align: center;
  }

  form {
    width: 50vw;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr;
    grid-template-rows: 1fr 1fr;

    label {
      grid-column: 1;
      grid-row: 1;
      justify-self: end;
      margin-right: 10px;
      font-size: 4rem;
      color: $blue;
    }

    input {
      grid-column: 2/-1;
      grid-row: 1;
      justify-self: start;
      width: 100%;
    }

    .forgot-password-buttons {
      grid-column: 2/-1;
      grid-row: 2;
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;

      button {
        width: 100%;
        justify-self: start;
      }

      a {
        justify-self: end;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .ForgotPassword {
    form {
      width: 80vw;
    }
  }
}