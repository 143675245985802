.Profile {

  .profile-form {
    width: 100%;

    &-item {
      height: 100px;
      width: 70vw;
      display: grid;
      grid-template-columns: 6fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      column-gap: 1rem;
      align-items: center;

      svg {
        font-size: 3rem;
      }
      
      input {
        height: 40px;
      }
  
      label {
        grid-row: 1;
        color: $medium-gray;
        margin-bottom: 5px;
      }
      
      input,
      svg,
      p,
      button {
        grid-row: 2;
      }
    }

    .password-button {
      width: 25%;
      min-width: 120px;
    }

    @media only screen and (max-width: 1000px) {
      .profile-form {
        row-gap: 0;

        &-item {
          margin-left: 0;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr 1fr 1fr;
          height: inherit;
  
          label {
            grid-column: 1/-1;
            grid-row: 1;
          }
  
          input {
            grid-column: 1/-1;
            grid-row: 2;
          }
  
          svg,
          p {
            grid-column: 1;
            grid-row: 3;
          }
  
          button {
            grid-column: 2;
            grid-row: 3;
            width: 50%;
            justify-self: right;
          }
        }

      }
      .password-match,
      .password-button {
        margin-left: 0;
      }
    }
  }
}