$pink: #FF509B;
$light-pink: rgb(226, 122, 167);
$dark-gray: #464646;
$medium-gray: #666666;
$light-gray: #ADADAD;
$super-light-gray: rgb(240, 240, 240);
$blue: #15AABF;
$light-blue: rgb(34, 118, 129);

@import url('https://fonts.googleapis.com/css?family=Rubik:300,400&display=swap');
