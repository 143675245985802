.AddSupplierMerchant {
  font-size: 2rem;
  display: grid;
  justify-items: center;

  button {
    width: fit-content;
  }
}

@media only screen and (max-width: 1000px) {
  .AddSupplierMerchant {
    .add-supplier-merchant-form {
      width: 100%;
    }
  }
}