.ViewListings {
  width: 100%;
  display: grid;
  row-gap: 10px;

  .data-grid-container {
    overflow-x: scroll;
    padding-bottom: 15px;
  }

  button {
    width: fit-content;
    position: sticky;
  }
}