.Users {

  form {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid;
  }

  .filtered-users-user {
    display: grid;
    grid-template-columns: 30px 1fr;
    align-items: center;
  }
}