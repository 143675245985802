.Loading {
  text-align: center;
  font-size: 4vw;
  color: $dark-gray;

  h1 {
    margin-top: 0;
    margin-bottom: 20px;
  }

  svg {
    font-size: 10rem;
    color: $pink;
  }
}