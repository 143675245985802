.Login {

  .login-form {
    width: 50vw;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr;
    grid-template-rows: 1fr 1fr 1fr;


    label {
      color: $blue;
      font-size: 4rem;
      text-align: center;
      grid-column: 1;
      margin-right: 10px;
    }

    input {
      grid-column: 2/-1;
    }

    button {
      width: 25%;
      min-width: 75px;
      grid-column: 2;
    }

    .forgot-link {
      grid-column: 4;
    }
  }
}