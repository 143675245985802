.Navbar {
  position: fixed;
  grid-column: 1/-1;
  width: 100%;
  height: 70px;
  margin-bottom: 30px;
  font-size: 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  background-color: $blue;
  // @include shadow;
  // -webkit-box-shadow: 0px 7px 11px 5px rgba(0,0,0,0.66); 
  // box-shadow: 0px 10px 11px 5px rgba(0,0,0,0.66);
  -webkit-box-shadow: 0px 7px 11px 5px rgba(0,0,0,0.3); 
  box-shadow: 0px 10px 11px 5px rgba(0,0,0,0.3);
  
  & a {
    transition: color ease-in-out 0.25s;
    color: white;
    border-bottom: 1px solid transparent;
  }

  & a:hover,
  .nav-link-active {
    color: $dark-gray;
  }

  .nav-left,
  .nav-right {
    display: grid;
    grid-template-rows: 1fr;
    column-gap: 30px;
    width: 100%;


    a {
      grid-row: 1;
      width: fit-content;
    }
  }
  
  .nav-left {
    justify-content: start;
    padding-left: 60px;
  }

  .nav-right {
    justify-content: end;
    padding-right: 60px;
  }
}

@media only screen and (max-width: 400px) {
  .Navbar {
    .nav-left {
      padding-left: 10px;
    }
    .nav-right {
      padding-right: 10px;
    }
  }
}