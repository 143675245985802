.MessageModal {
  color: $dark-gray;
  display: grid;

  h1 {
    margin: 0;
    font-size: 3vw;
  }

  p,
  button {
    font-size: 1.5vw;
    text-align: center;
  }

  button {
    margin-top: 1rem;
    justify-self: center;
  }
}