.FastChecking {
  font-size: 2rem;
  display: grid;
  justify-items: center;

  .fast-checking-form {
    width: 100%;
    display: grid;
    row-gap: 20px;

    h3 {
      justify-self: center;
    }

    .check-item {
      display: grid;
      row-gap: 10px;
      padding: 10px;
      border: 1px solid $super-light-gray;
      border-radius: 5px;
      transition: 0.3s ease-in-out;
    }

    button {
      justify-self: center;
    }
  }
}